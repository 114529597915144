import cn from "classnames";
import { useMemo } from "react";

import classes from "./styles.module.scss";

export default function ({ className = "", href, icon = "" }) {
  const src = useMemo(() => {
    switch (icon.toLowerCase()) {
      case "facebook":
        return "/assets/FacebookIcon.svg";
      case "twitter":
        return "/assets/TwitterIcon.svg";
      case "linkedin":
        return "/assets/LinkedInIcon.svg";
      case "instagram":
        return "/assets/InstagramIcon.svg";
      case "youtube":
        return "/assets/YoutubeIcon.svg";
      case "email":
        return "/assets/EmailIcon.svg";
      default:
        return "";
    }
  }, [icon]);

  return (
    <a
      className={cn(className, classes.SocialLink)}
      href={href}
      aria-label={icon}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={src} alt={icon} />
    </a>
  );
}
